
body {
    background: #f8f9fa;
    margin: 0; 
    font-family: Arial, sans-serif; 
    color: #000000;
}

.header {
    position: static;
    width: 100%;
    background-color: #ffffff;
    padding: 1rem; 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex; 
    justify-content: space-between; 
    align-items: center;
}

.sidebar {
    position: fixed;
    height: 100%;
    background-color: #D9D9D9;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    width: 250px; 
    padding-top: 20px;
    transition: width 0.3s; 
}

.main-content {
    margin-left: 250px;
    margin-top: 70px; 
    padding: 2rem; 
    transition: margin-left 0.3s;
    color: #000000;
}

.logo img {
    max-width: 90%;
    height: auto;
}

.nav-link {
    color: #000000; 
    transition: color 0.3s;
    text-decoration: none; 
}

.nav-link i {
    margin-right: 0.5rem;
}

.nav-link:hover {
    color: #adb5bd;
}

.logout-btn {
    background-color: #343a40;
    border-color: #343a40;
    transition: background-color 0.3s;
    color: #ffffff;
}

.logout-btn:hover {
    background-color: #292f36;
}

.vertical-line {
    border-left: 1px solid #ccc;
    height: 30px;
}

.custom-label {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    padding: 2px 6px;
    font-size: 12px;
    color: #333;
    border-radius: 3px;
}

@media (max-width: 768px) {
    .header {
        padding: 0.5rem; 
    }

    .main-content {
        margin-left: 70px; 
        padding: 1rem; 
    }

    .sidebar {
        width: 70px;
    }

    .nav-link {
        font-size: 0.9rem; 
    }
}

@media (max-width: 480px) {
    .header {
        flex-direction: column; 
        align-items: flex-start;
    }

    .nav-link {
        font-size: 0.8rem;
        margin-bottom: 0.5rem; 
    }

    .sidebar {
        width: 70px; 
        padding-top: 10px;
    }

    .main-content {
        margin-left: 70px;
        padding: 1rem; 
    }
}

.main-content {
    padding: 20px;
    background-color: #f8f9fa; 
    border-radius: 5px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-text h2 {
    margin-top: 20px;
    color: #000000; 
}

.header-text h5 {
    color: #6c757d;
}
